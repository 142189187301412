<script>
import { IMAGES } from "@/graphql/queries"
import { getDownloadUrl } from '@/utils/firebase';
import { mapState } from 'vuex'

  export default {
    components: {
    FilterDashboard: () => import('./Filter.vue'),
    },

    data: () => ({
      model: null,
      images: [],
    }),

    computed: {
      ...mapState('dashboard', ['filter'])
    },

    watch:{
      filter() {
        this.fetch()
      }
    },

    methods: {
      async fetch() {
        const filter = this.filter

        if (!filter.farmId || !filter.startAt || !filter.endAt) return

        const where = {}
        where.farm_id = { _eq: filter.farmId }
        where.captured_at = { _gte: filter.startAt, _lte: filter.endAt }
        const options = { query: IMAGES, variables: { where } }
        const { data } = await this.$apollo.query(options)
        if (data && data.images) {
          Promise
            .all(data.images.map(async image => {
              image.src = await getDownloadUrl(image.file_path.replace(/\..*/, '-web.jpg'))
              image.srcThumb = await getDownloadUrl(image.file_path.replace(/\..*/, '-thumb.jpg'))
              image.description = `${this.$options.filters.shortDate(image.captured_at)} - ${image.type}`
              return image
            }))
            .then(images => {
              const items = []
              this.images = []
              const indexList = []
              items[images[0].captured_at] = [images[0]]
              indexList[0] = images[0].captured_at
              for (let index = 1 ; index < images.length; index++) {
                if(!items[images[index].captured_at]) {
                  items[images[index].captured_at] = []
                  indexList.push(images[index].captured_at)
                }
                  items[images[index].captured_at].push(images[index])
              }
              for( let index = 0; index < indexList.length; index++) {
                this.images.push(items[indexList[index]])
              }
            })
        } else {
          this.images = []
        }
      },

      onClick(image) {
        this.$refs.silentbox.openOverlay(image)
      }

    }
  }
</script>

<template>
  <div>
    <filter-dashboard class="filter no-print"/>
    <v-sheet
      class="mx-auto"
      elevation="4"
    >
      <div v-if="images.length > 0">
        <v-slide-group
          v-for="(items,index) in images"
          :key="index"
          class="pa-2"
          active-class="success"
          show-arrows="desktop"
        >
          <v-slide-item
            v-for="item in items"
            :key="item.file_path"
            v-slot="{ active, toggle }"
          >
            <v-card
              :color="active ? undefined : 'grey lighten-1'"
              class="mb-5 ml-2 mr-2"
              height="130"
              width="215"
              @click="onClick(item)"
            >
              <v-img
                height="160"
                class="white--text align-end"
                :src="item.srcThumb"
              >
                <v-card-title>
                  <div class="green" style="
                    padding: 0 5px;
                  ">
                    {{ item.captured_at | shortDate }}
                  </div>
                </v-card-title>
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
      <v-card v-else>
        <v-card-title>
          <v-card-text class="text-center">
            <v-icon>mdi-image-broken-variant</v-icon>
            <span>Não foram encontradas imagens para o período selecionado.</span>
          </v-card-text>
        </v-card-title>
      </v-card>

      <silent-box ref="silentbox" :gallery="images" :preview-count=0></silent-box>
    </v-sheet>
  </div>
  
</template>

<style>
</style>